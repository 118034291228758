import { ROLE } from "./role";

export const ACCESS = {

	MAIN: [ROLE.ADMIN, ROLE.EDITOR],

	AUTH: [ROLE.ADMIN, ROLE.EDITOR, ROLE.SELLER_ONLINE, ROLE.SELLER_ON_STORE, ROLE.USER],
	CATALOG: [ROLE.ADMIN, ROLE.EDITOR, ROLE.SELLER_ONLINE, ROLE.SELLER_ON_STORE],
	WATCH_PRODUCTS: [ROLE.ADMIN, ROLE.EDITOR, ROLE.SELLER_ONLINE, ROLE.SELLER_ON_STORE],
	EDIT_PRODUCTS: [ROLE.ADMIN, ROLE.EDITOR, ROLE.SELLER_ONLINE],
	DELETE_PRODUCTS: [ROLE.ADMIN, ROLE.EDITOR, ROLE.SELLER_ONLINE],
	MAKE_ORDER: [ROLE.ADMIN, ROLE.EDITOR, ROLE.SELLER_ONLINE, ROLE.SELLER_ON_STORE],
	WATCH_SALES: [ROLE.ADMIN, ROLE.EDITOR, ROLE.SELLER_ONLINE, ROLE.SELLER_ON_STORE],
	WATCH_SALE_STATS: [ROLE.ADMIN, ROLE.EDITOR],

	USERS: [ROLE.ADMIN],
	LOGS: [ROLE.ADMIN, ROLE.EDITOR],

	EDIT_SCHEDULES: [ROLE.ADMIN, ROLE.EDITOR, ROLE.SELLER_ONLINE],

}
